<template>
  <div ref="manage" class="manage">
    <div class="df-row">
      <!-- 日期时间选择器 -->
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <!-- 日期时间选择器 -->
      <div class="df-col">
        <el-select
          v-model="submitData.status"
          placeholder="处理状态"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in statusList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <!-- 搜索框 -->
        <el-input placeholder="请输入" v-model="submitData.word" size="mini">
        </el-input>
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button type="primary" size="mini" @click="onSearchMessage"
          >查询</el-button
        >
      </div>

      <div class="df-col">
        <!-- 增加设备按钮 -->
        <el-button type="primary" size="mini" @click="onShowAdd"
          >增加设备</el-button
        >
      </div>

      <div class="df-col">
        <!-- 增加设备按钮 -->
        <el-button size="mini" @click="goto"
          >打开报警调度中心</el-button
        >
      </div>
    </div>

    <!-- start 表格 -->
    <el-table
      :data="tableData"
      :max-height="tableMaxHeight"
      border
      stripe
      size="mini"
      v-loading="loading"
    >
      <el-table-column prop="MemberName" label="用户姓名"> </el-table-column>
      <el-table-column prop="" label="警告设备类型">
        <template>紧急按钮</template>
      </el-table-column>
      <el-table-column label="报警时间">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scope.row.ArriveTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column prop="StateDesc" label="报警内容"> </el-table-column>
      <el-table-column label="警报处理" width="140">
        <template slot-scope="scope" v-if="scope.row.ClientHandle">
          <el-tag
            v-show="scope.row.Status != 2"
            type="success"
            effect="dark"
            @click="onShowHandle(scope.row)"
            >{{ scope.row.StatusName }}</el-tag
          >
          <span v-show="scope.row.Status == 2">{{ scope.row.StatusName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope" v-if="scope.row.ClientHandle">
          <el-button type="primary" size="mini" @click="onShowDetail(scope.row)">处理详情</el-button>
          <el-button type="danger" size="mini" @click="deleteMessageDetail(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 表格 -->

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 15, 20, 25]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      size="mini"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <!-- 添加设备对话框 -->
    <AddDeviceDialog ref="addDeviceDialog" @success="getMessageList"></AddDeviceDialog>

    <!-- 处理报警对话框 -->
    <DfHandlePressing
      ref="dfHandlePressing"
      @success="getMessageList"
    ></DfHandlePressing>

    <!-- 处理详情对话框 -->
    <HandleDetailDialog ref="handleDetailDialog"></HandleDetailDialog>
  </div>
</template>

<script>
import Aep from "@/api/aep.js";

import AddDeviceDialog from "./components/add-device-dialog.vue";
import HandleDetailDialog from "./components/handle-detail-dialog.vue";

export default {
  components: { AddDeviceDialog, HandleDetailDialog },

  data() {
    return {
      totalRecord: 0, // 总条数
      tableMaxHeight: "", // 表格最大高度
      submitData: {
        begin_date: "",
        end_date: "",
        word: "",
        status: -1,
        page_index: 1,
        page_size: 20,
      },
      statusList: [],
      multipleDate: [], // 日期时间选择器数据
      tableData: [], // 表格数据列表
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];

    this.onChangeDate();
    this.initMessageList();
    this.getMessageList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 68 + 32);
    },

    // 初始化列表查询
    async initMessageList() {
      try {
        let { data } = await Aep.initMessageList();
        this.statusList = data.statusList;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取查询列表
    async getMessageList() {
      try {
        let { data } = await Aep.getMessageList(this.submitData);
        this.totalRecord = data.filter.TotalRecord;
        this.tableData = data.list;
      } catch (err) {
        console.log(err);
      }
    },

    // 更新消息状态为处理中
    async acceptMessage(msgId) {
      try {
        let { errcode } = await Aep.acceptMessage({
          msg_id: msgId,
        });
        if (errcode == 0) {
          this.$message.success("状态改变成功");
          this.getMessageList();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 删除消息
    deleteMessageDetail(event){
      this.$confirm("删除后将无法恢复，确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: 'warning'
      }).then(async () => {
        try {
          let { errcode } = await Aep.deleteMessageDetail({
            msg_id: event.MsgId,
          });
          if(errcode == 0){
            this.$message.success("删除成功");
            this.getMessageList();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 查询按钮点击事件
    onSearchMessage() {
      this.submitData.page_index = 1;
      this.getMessageList();
    },

    // 日期时间改变事件
    onChangeDate() {
      this.submitData.begin_date =
        this.multipleDate.length > 0
          ? this.multipleDate[0]
          : this.util.getNowMonthToday();
      this.submitData.end_date =
        this.multipleDate.length > 0
          ? this.multipleDate[1]
          : this.util.getNowMonthToday();
      this.submitData.page_index = 1;
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getMessageList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getMessageList();
    },

    // 打开添加设备对话框
    onShowAdd() {
      this.$refs.addDeviceDialog.onShowDialog();
    },

    // 打开处理警报对话框
    onShowHandle(event) {
      if (event.Status == 0) {
        this.$confirm("是否前去处理。", "信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          this.acceptMessage(event.MsgId);
        });
      } else {
        this.$refs.dfHandlePressing.onShowDialog(event.MsgId);
      }
    },

    // 打开处理详情对话框
    onShowDetail(event){
      this.$refs.handleDetailDialog.onShowDialog(event.MsgId);
    },

    goto(){
      let routerUrl = this.$router.resolve({
          path: "/alarm",
        });
        window.open(routerUrl.href, "_blank");
    }
  },
};
</script>

<style lang="less" scoped>
.manage {
  position: relative;
  padding: 20px;
  box-sizing: border-box;

  .el-table {
    .el-tag {
      cursor: pointer;
    }
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }
}
</style>
