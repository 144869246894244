<template>
  <div class="handle-detail-dialog">
    <el-dialog
      title="处理详情"
      :visible.sync="showDialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="content-box">
        <el-form label-width="100px" :inline="false" size="mini">
          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="老人名称">{{ handleInfo.MemberName }}</el-form-item>
            </el-col>
            <el-col :span="colSpan">
              <el-form-item label="告警设备类型">紧急按钮</el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="处理人名称">{{ handleInfo.HandlerName }}</el-form-item>
            </el-col>
            <el-col :span="colSpan">
              <el-form-item label="处理进度">{{ handleInfo.StatusName }}</el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="警告时间">{{ util.dateFormat("YYYY-mm-dd HH:MM:SS", new Date(handleInfo.ArriveTime * 1000)) }}</el-form-item>
            </el-col>
            <el-col :span="colSpan">
              <el-form-item label="处理方式">{{ handleInfo.HandleModeName }}</el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="处理时间">{{ handleInfo.AcceptTime ? util.dateFormat("YYYY-mm-dd HH:MM:SS", new Date(handleInfo.AcceptTime * 1000)) : '' }}</el-form-item>
            </el-col>
            <el-col :span="colSpan">
              <el-form-item v-if="handleInfo.Status > 0" label="接收时长">{{ handleInfo.AcceptDuration }}</el-form-item>
              <!-- <el-form-item label="接收时长">{{ util.calculateTimeDifference(handleInfo.AcceptTime * 1000, handleInfo.ArriveTime * 1000) }}</el-form-item> -->
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="完成时间">{{ handleInfo.HandleTime ? util.dateFormat("YYYY-mm-dd HH:MM:SS", new Date(handleInfo.HandleTime * 1000)) : '' }}</el-form-item>
            </el-col>
            <el-col :span="colSpan">
              <el-form-item v-if="handleInfo.Status > 0" label="处理时长">{{ handleInfo.HandleDuration }}</el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="rowSpan">
              <el-form-item label="处理结果">{{ handleInfo.HandleResult }}</el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="onCloseDialog">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Aep from "@/api/aep.js";

export default {
  data() {
    return {
      msgId: "",
      rowSpan: 20,
      colSpan: 12,
      handleInfo: {},
      showDialog: false,
      submitDisabled: false,
    };
  },

  methods: {
    // 获取消息处理详情
    async getMessageHandleDetail() {
      try {
        let { data } = await Aep.getMessageHandleDetail({ msg_id: this.msgId });
        this.handleInfo = data
      } catch (err) {
        console.log(err);
      }
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },

    // 打开对话框
    onShowDialog(msgId) {
      this.msgId = JSON.parse(JSON.stringify(msgId));
      this.getMessageHandleDetail();
      this.showDialog = true;
    },
  },
};
</script>

<style lang="less" scoped></style>
