<template>
  <div class="inte-exch-record-add">
    <el-dialog
      title="新增设备"
      :visible.sync="showDialog"
      width="45%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="content-box">
        <el-form label-width="80px" size="mini">
          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="会员姓名">
                <DfMember
                  ref="DFMember"
                  size="mini"
                  @change="onSelectMember"
                ></DfMember>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="设备名称">
                <el-input v-model="submitData.DeviceName" clearable></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="设备编号">
                <el-input
                  v-model="submitData.DeviceSn"
                  placeholder="(选填)"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="IMEI">
                <el-input
                  v-model="submitData.IMEI"
                  :maxlength="15"
                  placeholder="由15位数字组成"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="IMSI">
                <el-input
                  v-model="submitData.IMSI"
                  placeholder="(选填)"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!-- start 脚部 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="onCloseDialog">取消</el-button>
        <el-button
          type="primary"
          size="small"
          :display="submitDisabled"
          @click="createDevice"
          >确定</el-button
        >
      </span>
      <!-- end 脚部 -->
    </el-dialog>
  </div>
</template>

<script>
import Aep from "@/api/aep.js";

export default {
  data() {
    return {
      // 行高
      rowSpan: 24,
      // 列宽
      colSpan: 8,
      // 提交数据
      submitData: {
        MemberGuid: "",
        DeviceName: "",
        DeviceSn: "",
        IMEI: "",
        IMSI: "",
      },
      // 是否显示对话框
      showDialog: false,
      // 提交防抖
      submitDisabled: false,
    };
  },

  methods: {
    // 创建新设备
    async createDevice() {
      let { submitData } = this;
      if (submitData.MemberGuid == "") {
        return this.$message.error("请选择会员");
      } else if (submitData.DeviceName == "") {
        return this.$message.error("请输入设备名称");
      } else if (submitData.IMEI == "" || !/^\d{15}$/.test(submitData.IMEI)) {
        return this.$message.error("IMEI由15位数字组成");
      }

      this.submitDisabled = true;

      try {
        let { errcode } = await Aep.createDevice(this.submitData);
        if (errcode == 0) {
          this.$message.success("新增设备成功");
          this.$emit("success");
          this.resetPageData();
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 选中会员回调
    onSelectMember(event) {
      this.submitData.MemberGuid = event.MemberGuid;
    },

    // 重置页面数据
    resetPageData() {
      this.submitData = {
        MemberGuid: "",
        DeviceName: "",
        DeviceSn: "",
        IMEI: "",
        IMSI: "",
      };
    },

    // 打开对话框
    onShowDialog() {
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.inte-exch-record-add {
  .content-box {
    ::v-deep .special-row {
      .el-input {
        width: 21%;
      }

      .el-button {
        margin-left: 10px;
      }
    }

    ::v-deep .is-disabled input {
      color: #606266;
    }
  }
}
</style>
